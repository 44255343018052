import React from 'react'
import { Link, graphql } from 'gatsby'
import '../styles/_styles.scss';
import Layout from '../components/layout'


const IndexPage = (props) => {
  const postList = props.data.allMarkdownRemark;
  return (
    <div style={{ paddingBottom: 0}}>
      <Layout>
        <div className="feed" style={{ paddingBottom: 0}}>
          {postList.edges.map(({ node }, i) => (
            <div className="preview">
              <Link to={node.fields.slug} key={i} >
              
                  {node.frontmatter.image && 
                    <div 
                      className="preview__figure"
                      style={{
                        backgroundImage: `url(${node.frontmatter.image.childImageSharp.fluid.src})`
                      }}
                    >
                      <div className="preview__details">
                        <div className="preview__title">
                          {node.frontmatter.name}                        
                        </div>
                      </div>
                    </div>
                  }
                                
              </Link>
            </div>
          ))}
        </div>
      </Layout>
    </div>
  )
}

export default IndexPage;

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___order] }) {
      edges {
        node {
          fields{
            slug
          }
          frontmatter {
            order
            name
            image {
              childImageSharp {
                resize(width: 1500, height: 1500) {
                  src
                }
                fluid(maxWidth: 786) {
                  ...GatsbyImageSharpFluid
                }
              }
           }
          }
        }
      }
    }

    site {
      siteMetadata {
          siteUrl
        }
     }

  }
`